<template>
  <v-container>
    <go-back-btn :routeToGo="routeToGo" />
    <PageHeader :title="title" class="mb-3" />
    <v-row>
      <!-- Filtros -->
      <v-col cols="12">
        <v-card flat class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container>
            <v-form
              v-model="isFormValid"
              ref="clientes-filters-form"
              id="clientes-filters-form"
              @submit.prevent="applyFilters()"
            >
              <v-row class="mr-1">
                <!-- Nombre de cliente -->
                <v-col cols="10" sm="4" md="4" class="pb-0">
                  <!-- Alinea a la izquierda -->
                  <v-text-field
                    v-model="clienteNombre"
                    label="Cliente"
                    single-line
                    :rules="[rules.maxLength(clienteNombre, 100)]"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="2" md="1" sm="3" justify="end">
                  <!-- Alinea a la derecha -->
                  <v-btn
                    color="primary"
                    :disabled="!isFormValid"
                    small
                    class="to-right mt-1"
                    :dark="isFormValid"
                    type="submit"
                    form="clientes-filters-form"
                  >
                    Aplicar
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <!-- Data table -->
    <v-card>
      <v-data-table
        class="elevation-2"
        :headers="clientesHeaders"
        :items="registrosClientes"
        :loading="loading"
        no-data-text="No hay datos disponibles para mostrar"
        :search="searchConsulta"
        item-key="clienteId"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="10" align-self="center" class="pt-5">
                <v-text-field
                  v-model="searchConsulta"
                  clearable
                  label="Buscar"
                  single-line
                  :append-icon="iconoSearch"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip top v-if="canAccesoBasicos">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="goToSeeAllDataClient(item)"
              >
                {{ seeIcon }}
                mdi-24px
              </v-icon>
            </template>
            <span>Ver todo</span>
          </v-tooltip>
          <v-tooltip top v-if="canAccesoBasicos">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="goSitios(item)"
              >
                {{ icon_setting2 }}
                mdi-24px
              </v-icon>
            </template>
            <span>Sitios por clientes</span>
          </v-tooltip>
          <v-tooltip top v-if="canAccesoBasicos">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="goServidores(item)"
              >
                {{ icon_setting }}
                mdi-24px
              </v-icon>
            </template>
            <span>Servidores por clientes</span>
          </v-tooltip>
          <v-tooltip top v-if="canAccesoBasicos">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                class="mr-2"
                @click="goUserBasicos(item)"
              >
                {{ user }}
                mdi-24px
              </v-icon>
            </template>
            <span>Usuarios básicos</span>
          </v-tooltip>
          <v-tooltip left top v-if="canAccesoAvanzados">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="goUserAvanzado(item)"
              >
                {{ iconoAdvanced }}
                mdi-24px
              </v-icon>
            </template>
            <span>Usuarios avanzados</span>
          </v-tooltip>
          <v-tooltip left top v-if="canAccesoDatos">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="goDatosAcceso(item)"
              >
                {{ data_acces }}
                mdi-24px
              </v-icon>
            </template>
            <span>Datos de acceso</span>
          </v-tooltip>
          <v-tooltip left top v-if="canConexionRdp">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                size="20"
                v-on="on"
                @click="goConexionRdp(item)"
              >
                {{ icon_conect }}
                mdi-24px
              </v-icon>
            </template>
            <span>Conexiones RDP</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader.vue";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import enums from "@/utils/enums/index.js";
import enumsAdmin from "@/utils/enums/modules/index.js";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  directives: { mask },
  components: { PageHeader, GoBackBtn },
  name: "Clientes",
  data() {
    return {
      title: "Clientes",
      isAvanzado: false,
      isFormValid: false,
      chevronUpIcon: enums.icons.CHEVRON_UP,
      chevronDownIcon: enums.icons.CHEVRON_DOWN,
      historic: enums.icons.HISTORIC,
      loading: false,
      seeIcon: enums.icons.SEE,
      filtrosGuardados: {},
      routeToGo: "/",
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      iconoAdvanced: enums.icons.USER_CORBATA,
      user: enums.icons.USER,
      rules: rules,
      menu1: false,
      menu2: false,
      userAvanzados: false,
      fechaDesde: null,
      fechaDesdeSelected: null,
      fechaHasta: null,
      fechaHastaSelected: null,
      tipoConsulta: null,
      clienteNombre: null,
      singleExpand: false,
      expanded: [],
      searchConsulta: null,
      iconoSearch: enums.icons.SEARCH_ICON,
      iconoExcel: enums.icons.EXCEL_ICON,
      data_acces: enums.icons.ACCES_DATA,
      icon_conect: enums.icons.ICON_CONNECTION,
      icon_setting: enums.icons.SETTING,
      icon_setting2: enums.icons.SETTING2,
      
      clientesHeaders: [
        {
          text: "Id cliente",
          align: "start",
          sortable: true,
          value: "clienteId",
        },
        { text: "Nombre de cliente", sortable: false, value: "clienteNombre" },
        {
          text: "Código equipo",
          align: "center",
          sortable: false,
          value: "equipoCod",
        },
        {
          text: "Equipo",
          align: "start",
          sortable: false,
          value: "equipo",
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" },
      ],
      registrosClientes: [],
      canAccesoBasicos: false,
      canAccesoAvanzados: false,
      canAccesoDatos: false,
      canConexionRdp: false,
      allowedActions: null,
      optionCodeUsuBasic: enumsAdmin.adminSistema.permissions.USUARIOS_BASICOS,
      optionCodeUsuAvan: enumsAdmin.adminSistema.permissions.USUARIOS_AVANZADOS,
      optionCodeDatosAcceso: enumsAdmin.adminSistema.permissions.DATOS_ACCESO,
      optionCodeConexionRdp: enumsAdmin.adminSistema.permissions.CONEXIONES_RDP,
    };
  },
  async created() {
    // await this.$store.dispatch(
    //   "user/updateFrequencyByOptionCode",
    //   this.optionCode
    // );
    this.setPermisos();
    if (this.$store.state.filtersClientes == null) this.applyFilters();
  },
  async mounted() {
    if (this.$store.state.filtersClientes != null) {
      this.clienteNombre = this.$store.state.filtersClientes.clienteNombre;
      this.applyFilters();
    }
    this.$store.state.filtersClientes = null;
  },
  methods: {
    ...mapActions({
      fetchUsuariosPorCliente: "clientesData/fetchUsuariosPorCliente",
    }),
    setPermisos() {
      this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
      this.allowedActions?.forEach((x) => {
        switch (x.optionCode) {
          case enumsAdmin.adminSistema.permissions.USUARIOS_BASICOS:
            this.canAccesoBasicos = true;
            break;
          case enumsAdmin.adminSistema.permissions.USUARIOS_AVANZADOS:
            this.canAccesoAvanzados = true;
            break;
          case enumsAdmin.adminSistema.permissions.DATOS_ACCESO:
            this.canAccesoDatos = true;
            break;
          case enumsAdmin.adminSistema.permissions.CONEXIONES_RDP:
            this.canConexionRdp = true;
            break;
          default:
            break;
        }
      });
    },
    goSitios(item) {
      this.$router.push({
        name: "SitiosSistemas",
        params: {
          itemsClientes: item,
        },
      });
      this.$store.state.filtersClientes = this.filtrosGuardados;
    },

    goServidores(item) {
      this.$router.push({
        name: "ServidoresSistemas",
        params: {
          itemsClientes: item
        },
      });
      this.$store.state.filtersClientes = this.filtrosGuardados;
    },
    goUserBasicos(item) {
      this.userAvanzados = false;
      this.$router.push({
        name: "UsersBasicsYAvan",
        params: {
          itemsClientes: item,
          userAvanzados: this.userAvanzados,
          parentOptionCode: this.optionCodeUsuBasic,
        },
      });
      this.$store.state.filtersClientes = this.filtrosGuardados;
    },
    goToSeeAllDataClient(item) {
      this.userAvanzados = false;
      this.$router.push({
        name: "SeeAllDataClient",
        params: {
          clientItem: item,
          parentOptionCode: this.optionCodeUsuBasic,
        },
      });
      this.$store.state.filtersClientes = this.filtrosGuardados;
    },
    goUserAvanzado(item) {
      this.userAvanzados = true;
      this.$router.push({
        name: "UsersBasicsYAvan",
        params: {
          itemsClientes: item,
          userAvanzados: this.userAvanzados,
          parentOptionCode: this.optionCodeUsuAvan,
        },
      });
      this.$store.state.filtersClientes = this.filtrosGuardados;
    },
    goDatosAcceso(item) {
      this.$router.push({
        name: "AccesoDatos",
        params: {
          itemsClientes: item,
        },
      });
      this.$store.state.filtersClientes = this.filtrosGuardados;
    },
    goConexionRdp(item) {
      this.$router.push({
        name: "ConexionRdp",
        params: {
          itemsClientes: item,
        },
      });
      this.$store.state.filtersClientes = this.filtrosGuardados;
    },
    closeModalUser() {
      this.openModalUsers = false;
    },
    async applyFilters() {
      this.$store.state.filtersClientes == null;
      this.loading = true;
      const data = {
        clienteNombre: this.clienteNombre != null ? this.clienteNombre : "",
      };
      this.filtrosGuardados = {
        clienteNombre: this.clienteNombre != null ? this.clienteNombre : "",
      };
      const response = await this.fetchUsuariosPorCliente(data);
      this.registrosClientes = response;
      this.loading = false;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .myLabel {
  color: white;
}
.to-right {
  float: right;
}
ul {
  list-style: none;
}
</style>
